.TopBar {
    display: flex;
    justify-content: space-between;
    padding: 2px 37px;
    border-radius: 15px;
    animation: moveDownAndOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-out;
    animation-duration: .3s;
}

.TopBar > .Title {
    font-weight: 400;
    font-size: 220%;
    margin: 0;
    color: #444;
    display: flex;
    align-items: center;
}

.TopBar > .Info {
    display: flex;
    white-space: nowrap;
    justify-content: center;
    align-items: flex-end;
}
